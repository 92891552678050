import * as React from 'react';
import styled, { css } from 'styled-components';

import { validateFormInputValue } from 'utils/validate';
import { formatMaskCharacters, formatUnmaskCharacters } from 'utils/format';

import EyeIcon from 'assets/svgs/eye-icon.svg';

const Wrapper = styled.div<{ isDisabled: boolean }>`
  ${({ isDisabled }) => isDisabled && css`
    cursor: not-allowed;
    opacity: 0.6;
    user-select: none;
  `}
`;

const Input = styled.input <{ disabled: boolean; value: string }>`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey[5]};
  box-sizing: border-box;
  outline: none;
  padding: 12px;
  transition: border .5s ease-in-out;
  transition: border-color .5s ease-in-out;
  transition: opacity .5s ease-in-out;
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;

  border-radius: 4px;
  -moz-appearance: textfield;

  :hover {
    border: 1px solid ${({ theme }) => theme.colors.yellow[0]};
  };

  :focus {
    border: 1px solid ${({ theme }) => theme.colors.grey[0]};
    color: ${({ theme }) => theme.colors.black};
  };

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  :-ms-input-placeholder {
    color:  ${({ theme }) => theme.colors.grey[3]};
    opacity: 1;
  }
  ::-ms-input-placeholder {
    color:  ${({ theme }) => theme.colors.grey[3]};
    opacity: 1;
  }
  ::placeholder {
    color:  ${({ theme }) => theme.colors.grey[3]};
    opacity: 1;
  }

  ${({ disabled, theme }) => disabled && css`
    cursor: not-allowed;
    user-select: none;
    :hover {
      border: 1px solid ${theme.colors.grey[4]};
    };

  `}
`;

const TextArea = styled.textarea <{ disabled: boolean; value: string }>`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey[5]};
  box-sizing: border-box;
  outline: none;
  padding: 12px;
  transition: border .5s ease-in-out;
  transition: border-color .5s ease-in-out;
  transition: opacity .5s ease-in-out;
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;

  border-radius: 4px;
  -moz-appearance: textfield;

  :hover {
    border: 1px solid ${({ theme }) => theme.colors.yellow[0]};
  };

  :focus {
    border: 1px solid ${({ theme }) => theme.colors.grey[0]};
    color: ${({ theme }) => theme.colors.black};
  };

  :-ms-input-placeholder {
    color:  ${({ theme }) => theme.colors.grey[3]};
    opacity: 1;
  }
  ::-ms-input-placeholder {
    color:  ${({ theme }) => theme.colors.grey[3]};
    opacity: 1;
  }
  ::placeholder {
    color:  ${({ theme }) => theme.colors.grey[3]};
    opacity: 1;
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${({ disabled, theme }) => disabled && css`
    cursor: not-allowed;
    user-select: none;
    :hover {
      border: 1px solid ${theme.colors.grey[4]};
    };

  `}
`;

export const InputLabel = styled.span<{ isRequired: boolean }>`
  position: relative;
  margin: 0 0 0 0.1em;
  color: ${({ theme }) => theme.colors.black};

  ${({ isRequired, theme }) => isRequired && css`
    &:after {
      content: ' *';
      color: ${theme.colors.red[0]};
    }
  `}
`;

export const ErrorLabel = styled.h3`
  color: ${({ theme }) => theme.colors.red[0]};
  font-size: 1em;
  margin: 0.1em 0 0 0.1em;
  padding: 0;
  position: relative;
  text-align: left;
`;


const Eye = styled(EyeIcon)<{ isPasswordVisible: boolean }>`
  position: absolute;
  margin-left: -50px;
  margin-top: 10px;

  ${({ isPasswordVisible, theme }: { isPasswordVisible: boolean; theme: ITheme }) =>
    isPasswordVisible && `fill: ${theme.colors.black}`};
`;

export const FormInput: React.FC<IFormInputProps> = ({
  className, error, handleChange, isDisabled = false,
  isRequired = false, label, name, placeholder, touched, type, value,
  handleBlur, inputRef, spellCheck = true, onFocus, maskType = 'default',
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);

  return (
    <Wrapper
      className={className}
      isDisabled={isDisabled}
    >
      {label && <InputLabel isRequired={isRequired}>{label}</InputLabel>}
      <Input
        autoComplete="off"
        disabled={isDisabled}
        ref={inputRef}
        name={name}
        onFocus={onFocus}
        onChange={(e) => {
          // check if cursor is at end of input
          const { selectionStart, selectionEnd } = e.target;
          const isAtEnd = selectionStart === selectionEnd &&
            selectionStart === e.target.value.length;
          let unmasked = formatUnmaskCharacters(e.target.value, maskType);
          if (
            maskType === 'percentage' &&
            'inputType' in e.nativeEvent &&
            // @ts-expect-error
            e.nativeEvent.inputType === 'deleteContentBackward' &&
            isAtEnd
          ) {
            unmasked = unmasked.slice(0, -1);
          }
          if (validateFormInputValue(unmasked, maskType)) {
            handleChange(unmasked);
          }
        }}
        onBlur={(e) => {
          if (handleBlur && validateFormInputValue(e.target.value, maskType)) {
            handleBlur(formatUnmaskCharacters(e.target.value, maskType));
          }
        }}
        spellCheck={spellCheck ? 'true' : 'false'}
        placeholder={placeholder}
        required={isRequired}
        type={type === 'password' ? (
          isPasswordVisible ? 'text' : 'password'
        ) : type}
        value={formatMaskCharacters(value ?? '', maskType)}
      />
      {type === 'password' && (
        <Eye
          isPasswordVisible={isPasswordVisible}
          onClick={() => {
            setIsPasswordVisible(!isPasswordVisible);
          }}
        />
      )}
      {touched && error && <ErrorLabel>{error}</ErrorLabel>}
    </Wrapper>
  );
};

export const FormTextArea: React.FC<IFormInputProps> = ({
  className, error, handleChange, isDisabled = false,
  isRequired = false, label, name, placeholder, touched, type, value,
  handleBlur,
}) => (
  <Wrapper
    className={className}
    isDisabled={isDisabled}
  >
    {label && <InputLabel isRequired={isRequired}>{label}</InputLabel>}
    <TextArea
      autoComplete="off"
      disabled={isDisabled}
      name={name}
      onChange={(e) => {
        handleChange(e.target.value);
      }}
      onBlur={(e) => {
        if (handleBlur) {
          handleBlur(e.target.value);
        }
      }}
      placeholder={placeholder}
      required={isRequired}
      value={value ? value.toString() : ''}
    />
    {touched && error && <ErrorLabel>{error}</ErrorLabel>}
  </Wrapper>
);
