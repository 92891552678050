import * as React from 'react';
import styled from 'styled-components';

import { OrangeTryItButton } from 'components/Button';
import { ErrorLabel, FormInput, InputLabel } from 'components/FormInput';

import { smallTabletMQ } from 'styles/breakpoints';

import signup from 'api/signup';
import { config } from 'constants/config';
import { useThrottledCallback } from 'hooks/inputThrottle';

const InputContainer = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Form = styled.form`
  display: flex;
  width: 550px;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
  border-radius: 6px;
  margin-top: 15px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  padding: 20px;
  min-height: 230px;
  ${smallTabletMQ('width: unset;')}
`;

const MaxWidthText = styled.p`
  max-width: 520px;
  ${smallTabletMQ('max-width: unset;')}
  padding-top: 0;
  color: ${({ theme }) => theme.colors.black};
`;

const CustomUrl = styled(FormInput)`
  width: 436px;
  ${InputLabel} {
    color: ${({ theme }) => theme.colors.black};
  }
  margin-bottom: 0;
  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  ${ErrorLabel} {
    position: absolute;
  }
  ${smallTabletMQ('width: 100%;')}
`;

const CustomUrlContainer = styled.div`
  display: flex;
  height: 90px;
`;

const StyledSpan = styled.span`
  background: ${({ theme }) => theme.colors.grey[5]};
  border: 1px solid ${({ theme }) => theme.colors.grey[5]};
  box-sizing: border-box;
  outline: none;
  padding: 12px;
  transition: border .5s ease-in-out;
  transition: border-color .5s ease-in-out;
  transition: opacity .5s ease-in-out;
  width: 130px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  margin-top: 18px;
  height: 42px;
  border-radius: 4px;
  -moz-appearance: textfield;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const LoginButton = styled(OrangeTryItButton)`
  ${smallTabletMQ('width: 100%;')}
`;


export const LoginForm: React.FC = () => {
  const INITIAL_STATE: ILoginFormState = {
    formErrors: {},
    formTouched: {},
    login: {
      url: '',
    },
  };

  const throttledValidateURL = useThrottledCallback((url: string) => {
    handleValidateURL(url);
  }, 500);

  const [formState, setFormState] = React.useState<ILoginFormState>(INITIAL_STATE);
  const [isURLNotTaken, setIsURLNotTaken] = React.useState<boolean | undefined>(undefined);
  const [isURLTooLong, setIsURLTooLong] = React.useState(false);

  const handleChangeValue = (key: keyof ILoginForm, value: string) => {
    setFormState({
      ...formState,
      formTouched: {
        ...formState.formTouched,
        [key]: true,
      },
      login: {
        ...formState.login,
        [key]: value,
      },
    });
  };

  const handleSubmit = () => {
    window.location.href = `https://${formState.login.url}.wirebuddy.ca/login`;
  };

  const handleValidateURL = (value: string | undefined) => {
    signup.get({ url: value ? value : (formState.login.url ?? '') }).then(() => {
      setIsURLNotTaken(true);
    }, () => {
      setIsURLNotTaken(false);
    });
  };

  const generateURLValidText = () => {
    let nameCheck;
    if (formState.formTouched.url) {
      if (!formState.login?.url || formState.login?.url.split(' ').join('')
        .replace(/[^0-9a-zA-Z-]/gi, '').length < config.minBusinessNameLength) {
        nameCheck = `Your business URL must be at least ${config.minBusinessNameLength} characters`;
      }

      if (isURLNotTaken) {
        nameCheck = 'This business URL does not exist';
      }

      if (isURLTooLong) {
        nameCheck = (`Your business URL can be a maximum of ` +
        `${config.maxBusinessNameLength} characters.`);
      }
    }

    return nameCheck;
  };

  return (
    <Form
      autoComplete="off"
      encType="application/json"
      onSubmit={(e) => {
        e.preventDefault();
        if (!isURLNotTaken && !isURLTooLong) {
          handleSubmit();
        }
      }}
    >
      <InputContainer>

        <CustomUrlContainer>
          <CustomUrl
            name="url"
            label="Business URL"
            placeholder="joescontracting"
            isRequired
            error={generateURLValidText()}
            touched={true}
            value={formState.login.url}
            handleChange={(value) => {
              if (value.length <= config.maxBusinessNameLength) {
                handleChangeValue(
                  'url',
                  value.split(' ').join('').replace(/[^0-9a-zA-Z-]/gi, '').toLowerCase()
                );
                if (value.length >= config.minBusinessNameLength) {
                  throttledValidateURL(value);
                }
                setIsURLTooLong(false);
              } else {
                setIsURLTooLong(true);
              }
            }}
            handleBlur={handleValidateURL}
          />
          <StyledSpan>.wirebuddy.ca</StyledSpan>
        </CustomUrlContainer>

        <MaxWidthText>
          Your business URL is how you access your WireBuddy account.
          Forgot your business URL? Check your email from WireBuddy.
        </MaxWidthText>
      </InputContainer>


      <LoginButton
        isDisabled={!!isURLNotTaken || isURLNotTaken === undefined}
      >
        Login
      </LoginButton>
    </Form>
  );
};
