import * as React from 'react';
import styled from 'styled-components';

import { smallTabletMQ } from 'styles/breakpoints';
import {
  Blue,
} from 'components/Containers';
import { Seo } from 'components/Seo';
import { CenteredWhiteHeader } from 'components/Text';
import { NavBar } from 'components/NavBar';
import { Footer } from 'components/Footer';

import { LoginForm } from 'forms/Login';

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px 0;
  width: 100%;
  ${smallTabletMQ('margin: 10px 30px;')}
  flex: 1;
`;

const StyledHeader = styled(CenteredWhiteHeader)`
  ${smallTabletMQ(`
    font-size: 18px;
    line-height: 23px;
    margin-top: 20px;
  `)}
`;

const BlueFlex = styled(Blue)`
  flex: 1 1 100%;
  display: flex;
  background-image: radial-gradient(
    circle at 50% 60%,
    rgba(243, 244, 246, 0.08) 15%,
    transparent
  );
`;

const Login = () => (
  <>
    <NavBar />
    <Seo
      title="WireBuddy - Login"
      description="All your jobs, simply in one place"
      lang="en"
      image="src/assets/svgs/WireBuddyLogoPartial.svg"
    />
    <BlueFlex>
      <ListContainer>
        <StyledHeader>Login to your WireBuddy account</StyledHeader>
        <LoginForm />
      </ListContainer>
    </BlueFlex>
    <Footer />
  </>
);

export default Login;
