import { convertToFloat } from 'utils/converter';

import * as dateFns from 'date-fns';

import {
  dateDayOfWeek,
  dateMonth,
  fullDate,
  fullDateTime,
  fullTime,
  dateShortYear,
  partialTimePretty,
  fullDateShortMonth,
} from 'constants/dateFormats';

const currencyFormatter = new Intl.NumberFormat('en-CA', {
  currency: 'CAD',
  minimumFractionDigits: 2,
  style: 'currency',
});

export const formatFirstLetterCapital = (str: string) => (
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
);

export const formatTitle = (title: string) => (
  title.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase())
);

export const parseDatabaseDate = (originalDate: string) => (
  dateFns.parseISO(originalDate)
);

export const formatDatabaseDateToPrettyDateMonth = (originalDate: string) => (
  dateFns.format(parseDatabaseDate(originalDate), dateMonth)
);

export const formatDatabaseDateToDashboard = (originalDate: string, monthRange: number) =>  (
  dateFns.format(
    parseDatabaseDate(originalDate),
    monthRange === 1 ? dateMonth : dateShortYear,
  )
);

export const formatDatabaseDateToMonth = (originalDate: string) => (
  dateFns.format(parseDatabaseDate(originalDate), dateShortYear)
);

export const formatDatabaseDateToPrettyDate = (originalDate: string) => (
  dateFns.format(parseDatabaseDate(originalDate), fullDate)
);

export const formatDatabaseDateToPrettyDateTime = (originalDate: string) => (
  dateFns.format(parseDatabaseDate(originalDate), fullDateTime)
);

export const formatDatabaseDateToPrettyTime = (originalDate: string) => (
  dateFns.format(parseDatabaseDate(originalDate), fullTime)
);

export const formatDateToPartialTime = (date: Date) => (
  dateFns.format(date, partialTimePretty)
);

export const formatDateToWeekDate = (date: Date) => (
  dateFns.format(date, dateDayOfWeek)
);

export const formatDateToDatabaseDate = (jsDate: Date) => (
  new Date(
    jsDate.getTime() - (jsDate.getTimezoneOffset() * 60000),
  ).toISOString().slice(0, 19).replace('T', ' ')
);

export const formatDateToPrettyDate = (jsDate: Date) => dateFns.format(jsDate, fullDateShortMonth);

export const formatPortalLocationPathToTitle = (title: string) => {
  // exceptions:
  if (title.includes('unreadMessages')) {
    return 'Unread Messages';
  }

  if (title.includes('notificationPreferences')) {
    return 'Preferences';
  }

  const titleSlashArray = title.split('/');

  return titleSlashArray[4].replace(/\w\S*/g, (txt) =>
    txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

export const formatPhoneNumber = (phoneNumber?: string) => {
  if (!phoneNumber) {
    return '';
  }

  const regexObj = /^(?:\+?1[-. ]?)?(?:\(?([0-9]{3})\)?[-. ]?)?([0-9]{3})[-. ]?([0-9]{4})$/;
  const parts = phoneNumber.match(regexObj);
  if (regexObj.test(phoneNumber) && parts) {
    let formattedNumber = '';
    if (parts[1]) {
      formattedNumber += `(${parts[1]}) `;
    }
    formattedNumber += `${parts[2]}-${parts[3]}`;

    return formattedNumber;
  }

  return phoneNumber;
};

export const formatCurrency = (amount?: number | string) => (
  currencyFormatter.format(bankersRound(amount))
);

export const bankersRound = (amount?: number | string, d = 2) => {
  const n = convertToFloat(amount);
  const x = n * Math.pow(10, d);
  const r = Math.round(x);
  const br = Math.abs(x) % 1 === 0.5 ? (r % 2 === 0 ? r : r - 1) : r;

  return br / Math.pow(10, d);
};

export const roundTo = (amount?: number | string, d = 2) => {
  const n = convertToFloat(amount);
  const x = n * Math.pow(10, d);
  const r = Math.round(x);

  return r / Math.pow(10, d);
};

export const formatToSnakeCase = (s: string) => (
  s.replace(/(?:^|\.?)([A-Z])/g, (_, y: string) => `_${y.toLowerCase()}`).replace(/^_/, '')
);

export const formatPostalCode = (s: string) => (
  !!s ? s.length === 6 ? `${s.slice(0, 3)} ${s.slice(3)}` : s : ''
);

export const formatTemplates = (
  message?: string, businessName?: string, customerName?: string,
) => (
  !!message ? message
    .replace('__CUSTOMER-NAME__', customerName ?? '__CUSTOMER-NAME__')
    .replace('__BUSINESS-NAME__', businessName ?? '__BUSINESS-NAME__')
    : undefined
);

const formatPhoneWhileTyping = (phoneNumber?: string) => {
  if (!phoneNumber) {
    return '';
  }

  let formattedNumber = `(${phoneNumber.substring(0, Math.min(phoneNumber.length, 3))}`;
  if (phoneNumber.length > 3) {
    formattedNumber += `) ${phoneNumber.substring(3, Math.min(phoneNumber.length, 6))}`;
  }
  if (phoneNumber.length > 6) {
    formattedNumber += `-${phoneNumber.substring(6, Math.min(phoneNumber.length, 10))}`;
  }

  return formattedNumber;
};

const formatPostalCodeWhileTyping = (postalCode?: string) => {
  if (!postalCode) {
    return '';
  }
  // This is to make sure that the postal code is formatted correctly
  postalCode = postalCode.replace(' ', '');
  let formattedPostalCode = '';
  formattedPostalCode += `${postalCode.substring(0, Math.min(postalCode.length, 3))}`;
  if (postalCode.length > 3) {
    formattedPostalCode += ` ${postalCode.substring(3, Math.min(postalCode.length, 6))}`;
  }

  return formattedPostalCode;
};

// UNMASK
export const formatUnmaskCharacters = (rawVal: string, maskType: FormInputMaskType) => {
  switch (maskType) {
    case 'postalCode': {
      return rawVal.replace(' ', '');
    }
    case 'percentage': {
      rawVal = rawVal.replace('%', '');
      while (rawVal.length > 1 && rawVal.startsWith('0')) {
        rawVal = rawVal.slice(1);
      }

      return rawVal;
    }
    case 'currency': {
      const val = rawVal.replace(' ', '').replace(/\$|,/g, '');
      if (!val) {
        return '';
      }

      return val;
    }
    case 'phone': {
      return rawVal.replace(/\D+/g, '');
    }

    default: {
      return rawVal;
    }
  }
};

// MASK
export const formatMaskCharacters = (
  rawVal: string,
  maskType: FormInputMaskType,
  force2DecimalPlaces?: boolean,
) => {
  switch (maskType) {
    case 'postalCode': {
      return formatPostalCodeWhileTyping(rawVal.toUpperCase());
    }
    case 'currency': {
      const val = rawVal.replace(' ', '').replace(/\$|,/g, '');

      return !val ? ''
        : !!force2DecimalPlaces
          ? formatCurrency(val) : `$${val.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    }
    case 'percentage': {
      if (rawVal.length === 0) {
        return '';
      }

      return `${rawVal}%`;
    }
    case 'phone': {
      return formatPhoneWhileTyping(rawVal);
    }

    default: {
      return rawVal;
    }
  }
};
