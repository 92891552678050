import React from 'react';

export const useThrottledCallback = (
  callback: (val: string) => void, timeout: number,
) => {
  const timeoutRef = React.useRef();
  const callbackRef = React.useRef(callback);
  const prevCallRef = React.useRef(0);

  React.useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  React.useEffect(() => {
    window.clearTimeout(timeoutRef.current);
  }, [timeout]);

  return React.useCallback((...args) => {
    window.clearTimeout(timeoutRef.current);

    function invoke() {
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      callbackRef.current(...args);
      prevCallRef.current = Date.now();
    }

    const elapsed = Date.now() - prevCallRef.current;

    if (elapsed >= timeout) {
      invoke();
    } else {
      // @ts-expect-error
      // eslint-disable-next-line
      timeoutRef.current = window.setTimeout(invoke, timeout - elapsed);
    }
  }, []);
};
