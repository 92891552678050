import { config } from 'constants/config';
export const validateEmail = (
  email?: string, isEmptyAllowed?: boolean
): IIsValid => {
  if (isEmptyAllowed && (!email || email?.length === 0)) {
    return {
      reason: undefined,
      valid: true,
    };
  }

  if (!email || !email.length) {
    return {
      reason: 'Please enter an email',
      valid: false,
    };
  }

  // eslint-disable-next-line max-len
  const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  if (!re.test(email.toLowerCase())) {
    return {
      reason: 'Please enter a valid email',
      valid: false,
    };
  }

  if (email.length > config.maxBusinessNameLength) {
    return {
      reason: `Email must be less than ${config.maxBusinessNameLength} characters`,
      valid: false,
    };
  }

  return {
    reason: undefined,
    valid: true,
  };
};

export const validateCurrency = (amount?: string): IIsValid => {
  if (!amount || !amount.length) {
    return {
      reason: 'Please enter an amount',
      valid: false,
    };
  }

  const re = /^\d+(?:\.\d{0,2})$/;

  if (!re.test(amount)) {
    return {
      reason: 'Please enter a valid amount in the form 99999.99',
      valid: false,
    };
  }

  return {
    reason: undefined,
    valid: true,
  };
};

export const validateName = (
  name?: string,
  nameType?: string,
  isEmptyAllowed?: boolean,
  // default max length is set to config.maxBusinessNameLength
  maxLength: number = config.maxBusinessNameLength,
  minLength = 0,
): IIsValid => {
  if (!!isEmptyAllowed && (!name || name?.length === 0)) {
    return {
      reason: undefined,
      valid: true,
    };
  }

  if (!name || !name.replace(/\s+/g, '').length || name.replace(/\s+/g, '').length < minLength) {
    return {
      reason: `${nameType
        ? nameType.charAt(0).toUpperCase() + nameType.slice(1) // captialize first letter
        : 'Name'
      } must be at least ${minLength} characters`,
      valid: false,
    };
  }

  if (name.length > maxLength) {
    return {
      reason: `Please enter a ${
        nameType ? `${nameType} ` : 'name'
      } less than ${maxLength} characters`,
      valid: false,
    };
  }

  return {
    reason: undefined,
    valid: true,
  };
};

export const errorMessage = (e: IGenericObject, defaultMessage?: string): string => (
  // eslint-disable-next-line
  e?.response?.data?.message ?? defaultMessage ?? 'Something went wrong, please try again'
);

export const isString = (value: unknown): boolean => (
  Object.prototype.toString.call(value) === '[object String]'
);

// Value passed in is unmasked
export const validateFormInputValue = (value: string, maskType: FormInputMaskType): boolean => {
  if (!value) {
    return true;
  }

  switch (maskType) {
    case 'postalCode': {
      if (value.length > 6) {
        return false;
      }
      const index = value.length - 1;
      const lastLetter = value.charAt(index);
      if (index % 2 === 0 && (/[a-zA-Z]/).test(lastLetter)) {
        return true;
      }

      if (index % 2 === 1 && (/[0-9]/).test(lastLetter)) {
        return true;
      }

      return false;
    }

    case 'phone': {
      if (value.length > 10) {
        return false;
      }

      return /^\d*$/.test(value);
    }

    case 'zipCode': {
      if (value.length > 5) {
        return false;
      }

      return /^\d*$/.test(value);
    }

    case 'number': {
      return /^\d*$/.test(value);
    }

    case 'currency':
    case 'percentage':
    case 'decimal': {
      return !!value.match(/^(\d*\.{0,1}\d{0,2}$)/);
    }

    default: {
      return true;
    }
  }
};
